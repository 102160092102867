@tailwind base;
@tailwind components;
@tailwind utilities;

/* Montserrat */
@font-face {
  font-family: "Montserrat";
  src: url("../public/fonts/Montserrat-Black.ttf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../public/fonts/Montserrat-BlackItalic.ttf") format("opentype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("../public/fonts/Montserrat-ExtraBold.ttf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../public/fonts/Montserrat-ExtraBoldItalic.ttf") format("opentype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("../public/fonts/Montserrat-Bold.ttf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../public/fonts/Montserrat-BoldItalic.ttf") format("opentype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("../public/fonts/Montserrat-SemiBold.ttf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../public/fonts/Montserrat-SemiBoldItalic.ttf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../public/fonts/Montserrat-Medium.ttf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../public/fonts/Montserrat-MediumItalic.ttf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../public/fonts/Montserrat-Regular.ttf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

/* Manrope */
@font-face {
  font-family: "Manrope";
  src: url("../public/fonts/Manrope-Regular.ttf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../public/fonts/Manrope-Light.ttf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../public/fonts/Manrope-Bold.ttf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../public/fonts/Manrope-ExtraBold.ttf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../public/fonts/Manrope-ExtraLight.ttf") format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../public/fonts/Manrope-Medium.ttf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../public/fonts/Manrope-SemiBold.ttf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

/* css variables */
:root {
  --footer_bg: #2A2A2A;
  --white: #fff;
  --switch_toggle_bg: #1E5420;
  --input_bg: #F5F5F7;
  --btn_color: #FFDD2D;
  --footer_mobile_bg: #245626;
  --footer_mobile_img_bg: #34FF3C;
  --full_identify_block_bg: #245626;
  --full_identify_btnback_bg: #cccccc;
  --full_identify_btn_bg: #eaeaea;
  --full_identify_first_icon_bg: #FFC658;
  --full_identify_second_icon_bg: #99B3E5;
  --full_identify_third_icon_bg: #00FF29;
  --full_identify_content_bg: #F5F5F5;
  --profile_bt_bg: #B3C6AA;
  --forward_arrow: #245626;
  --profile_card_bg: #2A2A2A;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

div.spinner {
  position: relative;
  width: 100px;
  height: 100px;
  display: inline-block;
  border-radius: 10px;
}

div.spinner div {
  width: 2%;
  height: 20%;
  background: #424242;
  position: absolute;
  left: 49%;
  top: 43%;
  opacity: 0;
  border-radius: 50px;
  box-shadow: 0 0 3px rgba(110, 105, 0, 0.2);
  animation: fade 1s linear infinite;
}

@keyframes fade {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.25;
  }
}

div.spinner div.bar1 {
  transform: rotate(0deg) translate(0, -130%);
  animation-delay: 0s;
}

div.spinner div.bar2 {
  transform: rotate(30deg) translate(0, -130%);
  animation-delay: -0.9167s;
}

div.spinner div.bar3 {
  transform: rotate(60deg) translate(0, -130%);
  animation-delay: -0.833s;
}

div.spinner div.bar4 {
  transform: rotate(90deg) translate(0, -130%);
  animation-delay: -0.7497s;
}

div.spinner div.bar5 {
  transform: rotate(120deg) translate(0, -130%);
  animation-delay: -0.667s;
}

div.spinner div.bar6 {
  transform: rotate(150deg) translate(0, -130%);
  animation-delay: -0.5837s;
}

div.spinner div.bar7 {
  transform: rotate(180deg) translate(0, -130%);
  animation-delay: -0.5s;
}

div.spinner div.bar8 {
  transform: rotate(210deg) translate(0, -130%);
  animation-delay: -0.4167s;
}

div.spinner div.bar9 {
  transform: rotate(240deg) translate(0, -130%);
  animation-delay: -0.333s;
}

div.spinner div.bar10 {
  transform: rotate(270deg) translate(0, -130%);
  animation-delay: -0.2497s;
}

div.spinner div.bar11 {
  transform: rotate(300deg) translate(0, -130%);
  animation-delay: -0.167s;
}

div.spinner div.bar12 {
  transform: rotate(330deg) translate(0, -130%);
  animation-delay: -0.0833s;
}

.special-bg {
  background: transparent;
}

;

.paymentGrid .item {
  @apply flex items-center justify-center text-center px-1 desktop:px-5 desktop:px-7 h-20 bg-white rounded-[10px];
}

body {
  @apply bg-[#3a383d] laptop:bg-white;
}

body:has(#transferPage, #paymentsPage) {
  @apply bg-[#3a383d] laptop:bg-white;
}

body:has(#AuthorizedUser) {
  @apply bg-white;
}